import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Process from "../assets/images/glasses-process.jpg"
import KiloImage from "../assets/images/lassebechmartinussen2-1440x679.jpg"

const Section = styled.div`
  width: 100%;
  min-height: 600px;

  padding: 40px 0px;

  @media screen and (min-width: 30em) {
    padding: 60px 0px;
  }

  ${props =>
    !props.noFlex &&
    `display: flex;
  align-items: center;
  text-align: center;`}

  ${props =>
    props.backgroundImage &&
    `background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  background-image: url(${props.backgroundImage});`}

  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
`

const Introduction = styled.div`
  padding: 40px 24px 20px 24px;
  margin-bottom: 8px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  @media screen and (min-width: 30em) {
    padding: 40px 24px 0px 40px;
  }

  @media screen and (min-width: 60em) {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    padding-left: 80px;
    padding-top: 80px;
    margin-left: 0;
    margin-right: 0;
  }
`

const ImageColumn = styled.div`
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;

  @media screen and (min-width: 30em) {
    padding: 40px;
  }

  @media screen and (min-width: 60em) {
    width: 60%;
    display: inline-block;
    padding-left: 80px;
    padding-top: 40px;
    margin-left: 0;
    margin-right: 0;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0px 20px;
  font-size: 24px;
  line-height: 1.5;
  font-family: "MissionGothicBold", sans-serif;
`

const Paragraph = styled.p`
  font-size: 16px;
  font-family: "MissionGothicBold", sans-serif;
  color: #000;
  line-height: 1.5;
`

class Kilo extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Kilo Design"
          keywords={[`freedom`, `human rights`, `kilo design`]}
          description="Kilo is an idea-driven industrial design studio that supports
          leading-edge clients in shaping the future. Founded in 2005 by
          award-winning designer Lars Larsen, Kilo is today among
          Scandinavia’s most influential studios. "
        />

        <Section minHeight="400" backgroundImage={KiloImage}>
          <Title className="w-100">
            <p className="w-100 tc z-max white">Kilo Design</p>
          </Title>
        </Section>
        <Section noFlex noMinHeight backgroundColor="#bcd4f0">
          <Introduction>
            <Paragraph>
              Kilo is an idea-driven industrial design studio that supports
              leading-edge clients in shaping the future. Founded in 2005 by
              award-winning designer Lars Larsen, Kilo is today among
              Scandinavia’s most influential studios. 
              <br />
              <br />
              “The collection is united by a number of unique features that run
              through all styles, connecting each individual design in a bonded
              relationship.”
              <br />
              <br />
              Kilo has designed the Freedom Collection to be crafted from a
              single layer of laser-cut stainless steel. All components and key
              features are folded and shaped from this one sheet of material in
              a holistic design language.
              <br />
              <br />
              As a fundamental mark in the Dear Leader brand identity, the Morse
              Code logo has been a driver for the collection from the beginning,
              and the mark is fully integrated on the temples of each style. The
              simplicity and precision of Morse Code is used in combination with
              a study of sunglass heritage and the specified desires of each
              activist. 
            </Paragraph>
          </Introduction>
          <ImageColumn>
            <img src={Process} alt="Kilo Design process" />
          </ImageColumn>
        </Section>
      </Layout>
    )
  }
}

export default Kilo
